<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'portauthorityvariablegrid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'portauthorityvariable',
			modelColumnDefs: {}
		};
	}
};
</script>
